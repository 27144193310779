import firebase from '../firebase/init'
export default {
    namespaced: true,
    state: {
        advertisers: [],
        paperEditions: [],
        activeEditionId: null,
        advertiseCategories: [
            {
                id: 0,
                price: 30,
                colors: "zwart / wit",
                formats: [
                    "8,5 x 3,5",
                ]
            },
            {
                id: 1,
                price: 45,
                colors: "zwart / wit",
                formats: [
                    "8,5 x 5,5",
                ]
            },
            {
                id: 2,
                price: 60,
                colors: "zwart / wit",
                formats: [
                    "8,5 x 7,4",
                ]
            },
            {
                id: 3,
                price: 75,
                colors: "zwart / wit",
                formats: [
                    "8,5 x 10,6",
                    "17 x 5,3"
                ]
            },
            {
                id: 4,
                price: 87.5,
                colors: "zwart / wit",
                formats: [
                    "8,5 x 14,1",
                    "17 x 7,1"
                ]
            },
            {
                id: 5,
                price: 100,
                colors: "zwart / wit",
                formats: [
                    "8,5 x 17,9",
                    "17 x 8,9"
                ]
            },
            {
                id: 6,
                price: 135,
                colors: "zwart / wit",
                formats: [
                    "8,5 x 25,8",
                    "17 x 12,9",
                    "25,9 x 8,5"
                ]
            },
            {
                id: 7,
                price: 167.5,
                colors: "zwart / wit",
                formats: [
                    "8,5 x 32,6",
                    "17 x 16,4",
                    "25,9 x 10,7"
                ]
            },
            {
                id: 8,
                price: 195,
                colors: "full color",
                formats: [
                    "8,5 x 32,6",
                    "17 x 16,4",
                    "25,9 x 10,7"
                ]
            },
            {
                id: 9,
                price: 300,
                colors: "zwart / wit",
                formats: [
                    "17 x 28,9",
                    "25,9 x 19"
                ]
            },
            {
                id: 10,
                price: 330,
                colors: "full color",
                formats: [
                    "17 x 28,9",
                    "25,9 x 19"
                ]
            },
            {
                id: 11,
                price: 445,
                colors: "full color",
                formats: [
                    "25,9 x 38,6"
                ]
            }
        ],
        activeAdvertisements: [],
        archiveAdvertisements: []
    },
    getters: {
    },

    mutations: {
        testFunction() {
            console.log('test');
        },

        loadAdvertisers: (state) => {
            state.advertisers = []
            let loadAdvertisers = firebase.functions().httpsCallable("loadAdvertisers");
            loadAdvertisers().then(result => {
                state.advertisers = result.data.advertisers
            }).catch((e) => {
                console.log('Error in loadAdvertisers: ' + e.message)
            })
        },
        saveAdvertiser: (state, payload) => {
            let saveAdvertiser = firebase.functions().httpsCallable("saveAdvertiser")
            saveAdvertiser(payload).then(result => {
                if (result.data.succes) {
                    //check if state.advertisers contains the payload.advertiser
                    let index = state.advertisers.findIndex(advertiser => advertiser.id == payload.advertiser.id)
                    if (index == -1) {
                        state.advertisers.push(payload.advertiser)
                    } else {
                        state.advertisers[index] = payload.advertiser
                    }
                    return
                } else {
                    alert(result.data.msg)
                }
            }).catch(r => {
                console.log("error in saveAdvertiser in store" + r.message)
            })
        },
        deleteAdvertiser: (state, payload) => {
            let deleteAdvertiser = firebase.functions().httpsCallable("deleteAdvertiser")
            deleteAdvertiser(payload).then(result => {
                if (result.data.succes) {
                    //filter out the deleted advertiser
                    state.advertisers = state.advertisers.filter(advertiser => advertiser.id != payload.id)
                } else {
                    alert(result.data.msg)
                }
            }).catch(r => {
                console.log("error in deleteAdvertiser" + r.message)
            })

        },
        addPaperEdition: (state, payload) => {
            let addPaperEdition = firebase.functions().httpsCallable("addPaperEdition")
            addPaperEdition(payload).then(result => {
                if (result.data.succes == true) {
                    state.paperEditions = result.data.paperEditions.editions
                    //loop the editions and set the active edition
                    state.paperEditions.forEach(edition => {
                        if (edition.active == true) {
                            state.activeEditionId = edition.id
                        }
                    });
                } else {
                    alert("error in addPaperEdition" + result.msg)
                }
            }).catch(r => {
                console.log("error in AddPaperEditionsYear " + r.message)
            })
        },
        loadPaperEditions: (state) => {
            state.paperEditions = []
            let loadPaperEditions = firebase.functions().httpsCallable("loadPaperEditions");
            loadPaperEditions().then(result => {
                if (result.data.paperEditions) {
                    state.paperEditions = result.data.paperEditions.editions
                    state.activeAdvertisements = result.data.activeAdvertisements
                    state.archiveAdvertisements = result.data.archiveAdvertisements
                    //loop the editions and set the active edition
                    state.paperEditions.forEach(edition => {
                        if (edition.active == true) {
                            state.activeEditionId = edition.id
                        }
                    });
                }
            }).catch((e) => {
                console.log('Error in loadPaperEditions: ' + e.message)
            })
        },
        savePaperEditions: (state, payload) => {
            let savePaperEditions = firebase.functions().httpsCallable("savePaperEditions");
            savePaperEditions(payload).then(result => {
                if (result.data.paperEditions) {
                    state.paperEditions = result.data.paperEditions.editions
                    //loop the editions and set the active edition
                    state.paperEditions.forEach(edition => {
                        if (edition.active == true) {
                            state.activeEditionId = edition.id
                        }
                    });
                }
            }).catch((e) => {
                console.log('Error in savePaperEditions: ' + e.message)
            })
        },
        switchActiveEdition: (state, payload) => {
            try {
                state.paperEditions.forEach(edition => {
                    if (edition.id == payload.id) {
                        edition.active = true
                    } else {
                        edition.active = false
                    }
                });
                this.savePaperEditions({ paperEditions: state.paperEditions })
            } catch (error) {
                console.log("error in switchActiveEdition: " + error.message)
            }
        },
        saveAdvertisement: (state, payload) => {
            let saveAdvertisement = firebase.functions().httpsCallable("saveAdvertisement");
            saveAdvertisement(payload).then(() => {
                    //check if state.advertisers contains the payload.advertiser
                    let index = state.activeAdvertisements.findIndex(advertisement => advertisement.id == payload.advertisement.id)
                    if (index == -1) {
                        state.activeAdvertisements.push(payload.advertisement)
                    } else {
                        state.activeAdvertisements[index] = payload.advertisement
                    }
                    return
            }).catch((e) => {
                console.log('Error in saveAdvertisement: ' + e.message)
            })
        },
        sendFacturation: (state, payload) => {
            let sendFacturation = firebase.functions().httpsCallable("sendPaperFacs");
            sendFacturation(payload).then((result) => {
                if(result.data.succes){
                    alert("Factuur is verzonden")
                } else {
                    alert("er is iets misgegaan")
                
                }
                return
            }).catch((e) => {
                console.log('Error in saveAdvertisement: ' + e.message)
            })
        }
    },
    actions: {
    },
}