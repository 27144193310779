<template>
    <div>
      <canvas id="invoiceCanvas" width="595" height="842"></canvas> <!-- A4 size in pixels at 72 DPI -->
    </div>
  </template>
  
  <script>
  import logo_ahc from "../../assets/logo_ahc.png";
  
  export default {
    name: 'InvoiceCanvas',
    data() {
      return {
        currentDate: new Date().toLocaleDateString(),
        nameClient: 'Client Name',
        addressClient: 'Client Address',
        postalCodeClient: 'Client Postal Code',
        title: 'Invoice Title',
        amount: '100.00' // Example amount
      };
    },
    mounted() {
      this.drawInvoice();
    },
    methods: {
      drawInvoice() {
        const canvas = document.getElementById('invoiceCanvas');
        if (canvas.getContext) {
          const ctx = canvas.getContext('2d');
  
          // Load the logo image
          const logo = new Image();
          logo.src = logo_ahc;
          logo.onload = () => {
            // Draw the logo at the top center
            const logoX = (canvas.width - 120) / 2;
            ctx.drawImage(logo, logoX, 20, 120, 120);
  
            // Draw the organization name below the logo
            ctx.font = 'bold 16px Arial';
            ctx.textAlign = 'center';
            ctx.fillText("Stichting Algemeen Hulster Carnaval 'De Vossen'", canvas.width / 2, 160);
  
            // Draw the current date on the right
            ctx.font = '16px Arial';
            ctx.textAlign = 'right';
            ctx.fillText(`Hulst, ${this.currentDate}`, canvas.width - 50, 200);
  
            // Draw the client details on the left
            ctx.textAlign = 'left';
            ctx.fillText(this.nameClient, 50, 200);
            ctx.fillText(this.addressClient, 50, 220);
            ctx.fillText(this.postalCodeClient, 50, 240);
  
            // Draw the invoice title below the client details
            ctx.font = 'bold 16px Arial';
            ctx.fillText(`Factuur: ${this.title}`, 50, 340);
  
            // Draw the introductory text
            ctx.font = '16px Arial';
            ctx.fillText(`Conform afspraak ontvangt u hierbij de factuur voor ${this.title}`, 50, 380);
  
            // Draw the total amount
            ctx.font = 'bold 16px Arial';
            ctx.textAlign = 'right';
            ctx.fillText(`Totaal €${this.amount}`, canvas.width - 50, 420);
  
            // Draw the payment instructions
            ctx.font = '16px Arial';
            ctx.textAlign = 'left';
            ctx.fillText('Gelieve dit bedrag over te maken op rekeningnummer', 50, 460);
            ctx.fillText('NL79 RABO 0150.7907.59 t.n.v. A.H.C. De Vossen o.v.v. title invoice.', 50, 480);
            ctx.fillText('Wij zijn als stichting vrijgesteld van BTW.', 50, 500);
  
            // Draw the closing text
            ctx.fillText('Wij danken u voor wederom een prettige samenwerking.', 50, 540);
  
            // Draw the organization contact details
            ctx.fillText('Stichting A.H.C. De Vossen', 50, 700);
            ctx.fillText('Tivoliweg 9', 50, 720);
            ctx.fillText('4561 HJ Hulst', 50, 740);
            ctx.fillText('info@ahcdevossen.nl', 50, 760);
            ctx.fillText('KvK: 41110374', 50, 780);
          };
        }
      }
    }
  }
  </script>
  
  <style scoped>
  canvas {
    border: 1px solid #000;
  }
  </style>