<template>
    <v-container>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Zoeken" class="ma-5" single-line
            hide-details></v-text-field>
        <v-data-table :headers="taskHeaders" :items="filteredTasks" class="elevation-1" disable-pagination
            hide-default-footer show-expand :single-expand="singleExpand" :expanded.sync="expanded" :search="search">
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-row v-if="isPreces" class="ma-2">
                        <v-col cols="12">
                            <v-icon color="#cc212f"
                                @click="deleteTaskDialogitem = true, taskToDelete = item">mdi-delete</v-icon>
                        </v-col>
                    </v-row>
                    <v-row class="ma-2">
                        <v-col cols="12">
                            <span
                                style="word-break: break-word; overflow-wrap: break-word; white-space: pre-wrap"><b>Omschrijving:</b><br />
                                {{ item.description }}</span>
                        </v-col>
                    </v-row>
                </td>
            </template>
            <template v-slot:[`item.name`]="{ item }">
            <span v-if="!item.finished">{{ item.name }}</span>
            <strike v-else>{{ item.name }}</strike>
          </template>
          <template v-slot:[`item.responsable`]="{ item }">
            <span v-if="!item.finished">{{ item.responsable }}</span>
            <strike v-else>{{ item.responsable }}</strike>
          </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="finish(item)">
                            {{ item.finished ?'mdi-check-circle-outline' : 'mdi-circle-outline' }}
                        </v-icon>
                    </template>
                    <span>{{ item.finished ? 'open' : 'sluiten' }}</span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.when`]="{ item }">
                <span v-if="!item.finished">
                    {{ getWhen(item) }}
                </span>
                <strike v-else>
                    {{ getWhen(item) }}
                </strike>
            </template>
            <template v-slot:[`item.draaiboek`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" :color="getColor(item)" dark>
                  mdi-circle
                </v-icon>
            </template>
                    <span>{{ getDraaiboek(item) }}</span>
                </v-tooltip>
              </template>
        </v-data-table>

        <v-dialog v-model="deleteTaskDialogitem" max-width="400">
            <v-card v-if="!buzzy">
                <v-card-title class="headline">{{taskToDelete.parent ? 'Taken van draaiboek verwijderen' : 'Taak verwijderen'}}</v-card-title>
                <v-card-text v-if="!taskToDelete.parent">
                    Weet je zeker dat je deze taak wilt verwijderen?
                </v-card-text>
                <v-card-text v-else>
                    Weet je zeker dat je alle taken van dit draaiboek wilt verwijderen? Wanneer je slechts 1 taak van het draaiboek wilt verwijderen moet je het draaiboek updaten en vervolgens de taken opnieuw toevoegen.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="deleteTaskDialogitem = false, taskToDelete = {}">Annuleren</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteTask()">Verwijderen</v-btn>
                </v-card-actions>
            </v-card>
            <v-card class="ma-5" elevation="0" v-if="buzzy">
                <v-card-text>
                    <v-row>
                        <v-progress-circular class="ma-5 ahcRed" :size="70" :width="7"
                            indeterminate></v-progress-circular>
                    </v-row>
                    <v-row>
                        <h3>Opslaan draaiboek</h3>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>

export default {
    name: "task-overview",
    props: {
        taskFilter: {
            type: String,
            default: "all"
        },
        tasks: {
            type: Object,
            default: () => {}
        },
        draaiboeken: {
            type: Array,
            default: () => []
        },
        commissionId: {
            type: String,
            required: true,
        },
        isPreces: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            search: "",
            singleExpand: false,
            expanded: [],
            taskHeaders: [
                { text: 'Draaiboek', value: 'draaiboek' },
                { text: 'Wat', value: 'name' },
                { text: '(Hoofd)verantwoordelijke', value: 'responsable' },
                { text: 'Wanneer', value: 'when' },
                { text: 'Acties', value: 'actions' },
            ],
            deleteTaskDialogitem: false,
            taskToDelete: {},
            buzzy: false,
            months: [
                { label: "Januari", value: 8 },
                { label: "Februari", value: 9 },
                { label: "Maart", value: 10 },
                { label: "April", value: 11 },
                { label: "Mei", value: 12 },
                { label: "Juni", value: 1 },
                { label: "Juli", value: 2 },
                { label: "Augustus", value: 3 },
                { label: "September", value: 4 },
                { label: "Oktober", value: 5 },
                { label: "November", value: 6 },
                { label: "December", value: 7 }
            ],
        }
    },
    computed: {
        filteredTasks() {
            let filteredTasks = []
            let keys = Object.keys(this.tasks)
            keys.forEach(key => {
                let taskCategory = this.tasks[key]
                taskCategory.forEach(task => {
                    switch (this.taskFilter) {
                        case "all":
                            filteredTasks.push(task);
                            break;
                        case "open":
                            if(!task.finished) filteredTasks.push(task);
                            break;
                        case "myOwn":
                            if(task.responsable == this.$store.state.currentUser.name && (!task.finished)) filteredTasks.push(task);
                            break;
                        default:
                            break;
                    }
                })
            })
            const upFrontItems = filteredTasks?.filter(item => item.timeType === "upFront");
      const otherItems = filteredTasks?.filter(item => item.timeType !== "upFront" && item.timeType !== "afterEvent");
      const afterEventItems = filteredTasks?.filter(item => item.timeType === "afterEvent");

         // Sort upFrontItems by period using the months array
         upFrontItems.sort((a, b) => {
        const periodA = this.months.find(month => month.label === a.period);
        const periodB = this.months.find(month => month.label === b.period);
        if (periodA && periodB) {
          return periodA.value - periodB.value;
        }
        return 0;
      });
      afterEventItems.sort((a, b) => {
        const periodA = this.months.find(month => month.label === a.period);
        const periodB = this.months.find(month => month.label === b.period);
        if (periodA && periodB) {
          return periodA.value - periodB.value;
        }
        return 0;
      });

      // Combine sorted upFrontItems with otherItems
      return [...upFrontItems, ...otherItems, ...afterEventItems];
        },
    },
    methods: {
        getColor(task) {
            let color = "white";
            if (task.grandParent) {
                this.draaiboeken.forEach(category => {
                    if (category.id === task.grandParent) {
                        color = category.color;
                    }
                })
            }
            return color;
        },
        getDraaiboek(task) {
            if (task.grandParent) {
                let draaiboek = this.draaiboeken.find(category => category.id === task.grandParent);
                return draaiboek.name;
            } else {
                return "Losse taak";
            }
        },
        getWhen(item) {
            if (item.timeType == "upFront" || item.timeType == "afterEvent") {
                return `${item.period}`;
            } else {
                return "Tijdens het evenement";
            }
        },
        deleteTask() {
            this.buzzy = true;
            if (this.taskToDelete.grandParent) {
                this.$store.dispatch("commissionStore/deleteDraaiboekTasks", {
                    draaiboekId: this.taskToDelete.grandParent,
                    commissionId: this.commissionId
                }).then((result) => {
                    if(result.success) {
                        this.deleteTaskDialogitem = false;
                        this.buzzy = false;
                        this.taskToDelete = {};
                    }
                })
            } else {
                this.$store.dispatch("commissionStore/deleteSingleTask", {
                    taskId: this.taskToDelete.id,
                    commissionId: this.commissionId
                }).then((result) => {
                    if(result.success) {
                        this.deleteTaskDialogitem = false;
                        this.buzzy = false;
                        this.taskToDelete = {};
                    }
                })
            }
        },
        finish(task) {
            task.finished = !task.finished;
            this.$store.dispatch("commissionStore/finishTask", {
                taskId: task.id,
                commissionId: this.commissionId
            }).then((result) => {
                if(result.success) {
                    this.buzzy = false;
                }
            })
        }
      

  
    
  
      
    }
}
</script>