<template>
  <v-container>
    <v-layout row wrap xs12 class="ma-2 justify-space-between">
      <h1 class="ma-4 d-flex">
        <span class="ahcRed">Notulen en Agenda's</span>
      </h1>
    </v-layout>
    <v-layout row wrap v-if="secretary" class="ma-3" name="layoutAgendaRecords">
      <v-flex xs12 md5 class="ma-3" name="flexAgenda">
        <v-card>
          <v-system-bar class="pa-4" color="#ebb63b">
            <span class="ahcRed subheading">
              <b>Agenda's</b>
            </span>
            <v-spacer></v-spacer>
            <div class="d-flex" v-if="checkDBPermission() && !addAgenda">
              <v-icon color="#cc212f" @click="addAgenda = true">mdi-file-plus-outline</v-icon>
            </div>
          </v-system-bar>
          <v-card-text v-if="!addAgenda && sortedAgenda.length == 0">
            <span>
              Er zijn nog geen agenda's geupload. Het is de taak van de
              (vice)secretaris om dit te verzorgen.
            </span>
          </v-card-text>
          <v-form class="pa-2" v-if="addAgenda">
            <h4>Nieuwe agenda uploaden</h4>
            <v-date-picker v-model="newAgenda.date" label="Datum van de vergadering" no-title scrollable>
            </v-date-picker>
            <v-textarea label="Opmerking (optioneel)" v-model="newAgenda.description">
            </v-textarea>
            <v-switch v-model="newAgenda.dbOnly" label="DB only"></v-switch>
            <v-file-input label="te uploaden document: " v-model="newAgenda.doc"
              accept=".pdf,.doc,.docx,.xml,.xls,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"></v-file-input>
            <v-btn @click="(addAgenda = false), (newAgenda = {})">Annuleren</v-btn>
            <v-btn v-if="newAgenda.date && newAgenda.doc" @click="saveNewAgenda()">Opslaan</v-btn>
          </v-form>
          <v-list v-if="sortedAgenda.length > 0 && !addAgenda" three-line>
            <v-list-item v-for="(agenda, i) in sortedAgenda" :key="i">
              <v-list-item-icon v-if="
                !agenda.dbOnly ||
                (agenda.dbOnly && checkDBPermission())
              " @click="downloadFile(agenda.title, agenda.doc_id, agenda.type)">
                <v-icon>mdi-file-download-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-if="!agenda.dbOnly">{{
                  agenda.title
                }}</v-list-item-title>
                <v-list-item-title v-if="agenda.dbOnly">{{ agenda.title }} - <strong>DB only</strong>
                </v-list-item-title>
                <v-list-item-subtitle v-if="agenda.description">{{
                  agenda.description
                }}</v-list-item-subtitle>
                <v-list-item-subtitle><small>{{ agenda.creationDate }} - {{ agenda.creator
                    }}</small></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon v-if="checkDBPermission()" @click="switchAgenda(agenda.id)">
                <v-icon v-if="!agenda.dbOnly">mdi-lock-outline</v-icon>
                <v-icon v-if="agenda.dbOnly">mdi-lock-open-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-icon v-if="checkDBPermission()"
                @click="(deleteAgendaDialog = true), (deleteAgenda = agenda.id)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card>
      </v-flex>

      <v-flex xs12 md5 class="ma-3" name="flexRecords">
        <v-card>
          <v-system-bar class="pa-4" color="#ebb63b">
            <span class="ahcRed subheading">
              <b>Notulen</b>
            </span>
            <v-spacer></v-spacer>
            <div class="d-flex" v-if="checkDBPermission() && !addRecord">
              <v-icon color="#cc212f" @click="addRecord = true">mdi-file-plus-outline</v-icon>
            </div>
          </v-system-bar>
          <v-card-text v-if="!addRecord && sortedRecords.length == 0">
            <span>
              Er zijn nog geen notulen geupload. Het is de taak van de
              (vice)secretaris om dit te verzorgen.
            </span>
          </v-card-text>
          <v-form class="pa-2" v-if="addRecord">
            <h4>Nieuwe notulen uploaden</h4>
            <v-date-picker v-model="newRecord.date" label="Datum van de vergadering" no-title scrollable>
            </v-date-picker>
            <v-textarea label="Opmerking (optioneel)" v-model="newRecord.description">
            </v-textarea>
            <v-switch v-model="newRecord.dbOnly" label="DB only"></v-switch>
            <v-file-input label="te uploaden document: " v-model="newRecord.doc"
              accept=".pdf,.doc,.docx,.xml,.xls,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"></v-file-input>
            <v-btn @click="(addRecord = false), (newRecord = {})">Annuleren</v-btn>
            <v-btn v-if="newRecord.date && newRecord.doc" @click="saveNewRecord()">Opslaan</v-btn>
          </v-form>
          <v-list v-if="sortedRecords.length > 0 && !addRecord" three-line>
            <v-list-item v-for="(record, i) in sortedRecords" :key="i">
              <v-list-item-icon v-if="
                !record.dbOnly ||
                (record.dbOnly && checkDBPermission())
              " @click="downloadFile(record.title, record.doc_id, record.type)">
                <v-icon>mdi-file-download-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-if="!record.dbOnly">{{
                  record.title
                }}</v-list-item-title>
                <v-list-item-title v-if="record.dbOnly">{{ record.title }} - <strong>DB only</strong>
                </v-list-item-title>
                <v-list-item-subtitle v-if="record.description">{{
                  record.description
                }}</v-list-item-subtitle>
                <v-list-item-subtitle><small>{{ record.creationDate }} - {{ record.creator
                    }}</small></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon v-if="checkDBPermission()" @click="switchRecord(record.id)">
                <v-icon v-if="!record.dbOnly">mdi-lock-outline</v-icon>
                <v-icon v-if="record.dbOnly">mdi-lock-open-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-icon v-if="checkDBPermission()"
                @click="(deleteRecordDialog = true), (deleteRecord = record.id)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card>
      </v-flex>
    </v-layout>
    <v-card class="ma-5" elevation="0" v-if="!secretary">
      <v-row class="d-flex justify-space-around mb-6">
        <v-progress-circular class="ma-5 ahcRed" :size="70" :width="7" indeterminate></v-progress-circular>
      </v-row>
      <v-row class="d-flex justify-space-around mb-6">
        <h3>Inladen documenten...</h3>
      </v-row>
    </v-card>
    <v-dialog transition="dialog-top-transition" max-width="300" v-model="deleteAgendaDialog">
      <v-card v-if="!buzzy">
        <v-card-title class="headerClass">Agenda verwijderen?</v-card-title>
        <v-card-text>Deze actie is onomkeerbaar</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text
            @click="(deleteAgendaDialog = false), (deleteAgenda = null)">Annuleren</v-btn>

          <v-btn color="green darken-1" text @click="deleteAgendaF(deleteAgenda)">Verwijderen</v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular class="ma-5 ahcRed" :size="70" :width="7" indeterminate></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Verwijderen document</h3>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog transition="dialog-top-transition" max-width="300" v-model="deleteRecordDialog">
      <v-card v-if="!buzzy">
        <v-card-title class="headerClass">Notulen verwijderen?</v-card-title>
        <v-card-text>Deze actie is onomkeerbaar</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text
            @click="(deleteRecordDialog = false), (deleteRecord = null)">Annuleren</v-btn>

          <v-btn color="green darken-1" text @click="deleteRecordF(deleteRecord)">Verwijderen</v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular class="ma-5 ahcRed" :size="70" :width="7" indeterminate></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Verwijderen document</h3>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import firebase from "firebase";
import Axios from "axios";
export default {
  data() {
    return {
      newAgenda: {},
      newRecord: {},
      addAgenda: false,
      addRecord: false,
      deleteAgenda: null,
      deleteRecord: null,
      deleteAgendaDialog: false,
      deleteRecordDialog: false,
      buzzy: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    secretary() {
      return this.$store.state.secretary;
    },
    sortedAgenda() {
      let agendas = this.secretary.docs.agenda ?? [];
      agendas.sort((a, b) => {
        const dateA = new Date(a.title.split(' - ')[0]);
        const dateB = new Date(b.title.split(' - ')[0]);

        return dateB - dateA;
      });
      return agendas;
    },
    sortedRecords() {
      let records = this.secretary.docs.records ?? [];
      records.sort((a, b) => {
        const dateA = new Date(a.title.split(' - ')[0]);
        const dateB = new Date(b.title.split(' - ')[0]);

        return dateB - dateA;
      });
      return records;
    },
    userName() {
      return this.$store.state.currentUser.name;
    },
  },
  methods: {
    checkDBPermission() {
      if (this.currentUser?.permissions?.commissions?.includes("6df318d6-bf54-4648-9a63-84e76d0191bf") || this.currentUser?.id === "kXMcLKLuZnU6jkKD6zeO5C3tUwk2") {
        return true;
      } else {
        return false;
      }
    },


    saveNewAgenda() {
      try {
        let id = Date.now();
        let creationDate = new Date();
        let dd = String(creationDate.getDate()).padStart(2, "0");
        let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = creationDate.getFullYear();
        creationDate = dd + "/" + mm + "/" + yyyy;
        let creator = this.userName;

        let agenda = {
          id: id,
          title: this.newAgenda.date + " - Agenda",
          description: this.newAgenda.description,
          dbOnly: this.newAgenda.dbOnly,
          doc_id: id,
          creator: creator,
          creationDate: creationDate,
          type: this.newAgenda.doc.name.split(".").pop(),
        };

        let storageRef = firebase.storage().ref();
        storageRef.child(`docs/secretary/${id}`).put(this.newAgenda.doc);
        this.secretary.docs.agenda.push(agenda);
        this.$store.dispatch("saveSecretary");
        this.newAgenda = {};
        this.addAgenda = false;
      } catch (error) {
        console.log(error.message);
      }
    },
    saveNewRecord() {
      try {
        let id = Date.now();
        let creationDate = new Date();
        let dd = String(creationDate.getDate()).padStart(2, "0");
        let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = creationDate.getFullYear();
        creationDate = dd + "/" + mm + "/" + yyyy;
        let creator = this.userName;

        let agenda = {
          id: id,
          title: this.newRecord.date + " - Notulen",
          description: this.newRecord.description,
          dbOnly: this.newRecord.dbOnly,
          doc_id: id,
          creator: creator,
          creationDate: creationDate,
          type: this.newRecord.doc.name.split(".").pop(),
        };

        let storageRef = firebase.storage().ref();
        storageRef.child(`docs/secretary/${id}`).put(this.newRecord.doc);
        this.secretary.docs.records.push(agenda);
        this.$store.dispatch("saveSecretary");
        this.newRecord = {};
        this.addRecord = false;
      } catch (error) {
        console.log(error.message);
      }
    },
    downloadFile(title, id, file_type) {
      let metadata;
      if (file_type === ("doc" || "dot")) {
        metadata = "application/msword";
      } else if (file_type === "docx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      } else if (file_type === ("xls" || "xlt" || "xla")) {
        metadata = "application/vnd.ms-excel";
      } else if (file_type === "xlsx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (file_type === ("ppt" || "pot" || "pps" || "ppa")) {
        metadata = "application/vnd.ms-powerpoint";
      } else if (file_type === "pptx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      } else if (file_type === "mdb") {
        metadata = "application/vnd.ms-access";
      } else if (file_type === "pdf") {
        metadata = "application/pdf";
      }

      let filename = title;
      let file = firebase.storage().ref(`docs/secretary/${id}`);
      file.getDownloadURL().then((url) => {
        Axios.get(url, { responseType: "blob" })
          .then(({ data }) => {
            const blob = new Blob([data], {
              type: metadata,
            });

            const url = document.createElement("a");
            url.href = URL.createObjectURL(blob);
            url.download = filename;
            url.click();
            URL.revokeObjectURL(url.href);
          })
          .catch((error) => alert(error));
      });
    },
    deleteAgendaF(id) {
      this.buzzy = true;
      for (let i = 0; i < this.secretary.docs.agenda.length; i++) {
        if (this.secretary.docs.agenda[i].id === id) {
          if (this.secretary.docs.agenda[i].doc_id) {
            let storageRef = firebase.storage().ref();
            storageRef.child(`docs/secretary/${id}`).delete();
          }
          this.secretary.docs.agenda.splice(i, 1);
          this.$store.dispatch("saveSecretary");
        }
      }
      this.deleteAgenda = null;
      setTimeout(() => {
        this.deleteAgendaDialog = false;
        this.buzzy = false;
      }, 2000);
    },
    deleteRecordF(id) {
      this.buzzy = true;
      for (let i = 0; i < this.secretary.docs.records.length; i++) {
        if (this.secretary.docs.records[i].id === id) {
          if (this.secretary.docs.records[i].doc_id) {
            let storageRef = firebase.storage().ref();
            storageRef.child(`docs/secretary/${id}`).delete();
          }
          this.secretary.docs.records.splice(i, 1);
          this.$store.dispatch("saveSecretary");
        }
      }
      this.deleteRecord = null;
      setTimeout(() => {
        this.deleteRecordDialog = false;
        this.buzzy = false;
      }, 2000);
    },
    switchAgenda(id) {
      for (let i = 0; i < this.secretary.docs.agenda.length; i++) {
        if (this.secretary.docs.agenda[i].id === id) {
          this.secretary.docs.agenda[i].dbOnly =
            !this.secretary.docs.agenda[i].dbOnly;
          this.$store.dispatch("saveSecretary");
        }
      }
    },
    switchRecord(id) {
      for (let i = 0; i < this.secretary.docs.records.length; i++) {
        if (this.secretary.docs.records[i].id === id) {
          this.secretary.docs.records[i].dbOnly =
            !this.secretary.docs.records[i].dbOnly;
          this.$store.dispatch("saveSecretary");
        }
      }
    },
  },
  beforeCreate() {
    this.$store.dispatch("loadSecretary");
  },
};
</script>

<style></style>