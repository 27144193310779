<template>
    <v-container>
        <v-layout row wrap xs12 class="ma-2 justify-space-between">
            <h1 class="ma-4 d-flex">
                <span class="ahcRed">Commissielijst</span>
            </h1>
            <v-spacer></v-spacer>
            <!-- <div class="d-flex" v-if="currentUser && currentUser.role == 'Admin'">
                <v-icon @click="newEntryDialog = true" size="60" color="#ebb63b">mdi-plus-circle</v-icon>
            </div> -->
        </v-layout>
        <v-layout row wrap class="ma-3" name="layoutWorkGroupsModule">
            <v-flex xs12 name="flexWorkgroups">
                <v-flex class="ma-2">
                    <v-card>
                        <v-system-bar class="pa-4" color="#ebb63b"> </v-system-bar>
                        <v-card-text>
                            <template class="ma-4">
                                <v-data-table :headers="headers" :items="convertedCommissions" class="elevation-0"
                                    hide-default-footer loading-text="De commissielijst wordt ingeladen.."
                                    disable-pagination>
                                    <!-- <template v-slot:[`item.actions`]="{ item }"
                                        v-if="currentUser && currentUser.role == 'Admin'">
                                        <v-icon small class="ml-5" @click="(editItem = item), (editItemDialog = true)">
                                            mdi-pencil
                                        </v-icon>
                                    </template> -->
                                </v-data-table>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-flex>
        </v-layout>
        <!-- <v-card class="ma-5" elevation="0" v-if="workgroups.length == 0">
            <v-row class="d-flex justify-space-around mb-6">
                <v-progress-circular class="ma-5 ahcRed" :size="70" :width="7" indeterminate></v-progress-circular>
            </v-row>
            <v-row class="d-flex justify-space-around mb-6">
                <h3>Inladen commissielijst...</h3>
            </v-row>
        </v-card> -->
        <!-- <v-dialog transition="dialog-top-transition" max-width="900" v-model="newEntryDialog">
            <v-card v-if="!buzzy">
                <v-toolbar color="#ebb63b" class="text--white">
                    <span>Nieuwe commissie</span>
                    <v-spacer></v-spacer>
                    <v-icon @click="
                (newCommission = {}), (newEntryDialog = false)
                ">mdi-close-outline</v-icon>
                </v-toolbar>
                <v-card-text>
                    <v-form ref="addComForm">
                        <v-text-field v-model="newCommission.name" label="Name" required></v-text-field>
                        <v-text-field v-model="newCommission.index" label="Nummer" required type="number"></v-text-field>
                        <v-textarea v-model="newCommission.description" label="Description" required></v-textarea>
                        <v-select v-model="newCommission.preces" :items="users" item-text="name" item-value="id"
                            label="Preces" required></v-select>
                        <v-select v-model="newCommission.members" :items="users" label="Leden" item-text="name"
                            item-value="id" color="#cc212f" :menu-props="{ top: true, offsetY: true }" light multiple
                            hint="Exclusief de voorzitter" persistent-hint required></v-select>
                        <v-select v-model="newCommission.oldCommissions" :items="oldWorkgroups" item-text="title"
                            item-value="index" label="Oude commissies die hier onder vallen" color="#cc212f"
                            :menu-props="{ top: true, offsetY: true }" light multiple></v-select>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="validateAndSave">Save</v-btn>
                </v-card-actions>
                <div class="text-center mb-2">
                    <span class="center red--text" v-if="feedback">{{ feedback }}</span>
                </div>
            </v-card>
            <v-card elevation="0" v-if="buzzy">
                <v-layout>
                    <v-flex xs12 class="d-flex justify-center ma-4">
                        <v-progress-circular class="ahcRed ma-5" :size="70" :width="7"
                            indeterminate></v-progress-circular>
                    </v-flex>
                    <v-flex xs12 class="d-flex justify-center ma-4">
                        <h3>Commissie aanmaken</h3>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog> -->
        <!-- <v-dialog transition="dialog-top-transition" max-width="900" v-model="editItemDialog">
            <v-card v-if="!buzzy">
                <v-toolbar color="#ebb63b" class="text--white">
                    <span>Commissie aanpassen</span>
                    <v-spacer></v-spacer>
                    <v-icon @click="
                (editItem = {}), (editItemDialog = false), (editItemStepper = 1)
                ">mdi-close-outline</v-icon>
                </v-toolbar>
                <v-stepper v-model="editItemStepper" vertical v-if="editItem">
                   
                    <v-stepper-step color="#ebb63b" :complete="editItemStepper > 1" step="1">Wat is het volgnummer van
                        de
                        commissie?</v-stepper-step>

                    <v-stepper-content step="1">
                        <v-text-field disabled label="volgnummer" v-model="editItem.index" type="number"></v-text-field>

                        <v-btn v-if="editItem.index" color="#ebb63b" @click="editItemStepper = 2">Volgende</v-btn>
                    </v-stepper-content>

                    
                    <v-stepper-step color="#ebb63b" :complete="editItemStepper > 2" step="2">Wat is de naam van de
                        commissie?</v-stepper-step>
                    <v-stepper-content step="2">
                        <v-text-field v-model="editItem.title" label="Naam"></v-text-field>
                        <v-btn v-if="editItem.title" color="#ebb63b" @click="editItemStepper = 3">Volgende</v-btn>
                        <v-btn text @click="editItemStepper -= 1">Terug</v-btn>
                    </v-stepper-content>

                  
                    <v-stepper-step :complete="editItemStepper > 3" step="3" color="#ebb63b">Wie is de
                        voorzitter?</v-stepper-step>

                    <v-stepper-content step="3">
                        <v-select v-model="editItem.preces" :items="users" color="#cc212f"
                            :menu-props="{ top: true, offsetY: true }" light></v-select>
                        <v-btn color="#ebb63b" v-if="editItem.preces" @click="editItemStepper = 4">Volgende</v-btn>
                        <v-btn text @click="editItemStepper -= 1">Terug</v-btn>
                    </v-stepper-content>

                    
                    <v-stepper-step color="#ebb63b" :complete="editItemStepper > 4" step="4">Wie zijn er lid van de
                        commissie?</v-stepper-step>
                    <v-stepper-content step="4">
                        <v-select v-model="editItem.members" :items="users" color="#cc212f"
                            :menu-props="{ top: true, offsetY: true }" light multiple hint="Exclusief de voorzitter"
                            persistent-hint></v-select>

                        <v-btn v-if="editItem.members" color="#ebb63b"
                            @click="editWorkgroup(), (buzzy = true)">Aanmaken</v-btn>
                        <v-btn text @click="editItemStepper -= 1">Terug</v-btn>
                    </v-stepper-content>
                </v-stepper>
                <div class="text-center mb-2">
                    <span class="center red--text" v-if="feedback">{{ feedback }}</span>
                </div>
            </v-card>
            <v-card elevation="0" v-if="buzzy">
                <v-layout>
                    <v-flex xs12 class="d-flex justify-center ma-4">
                        <v-progress-circular class="ahcRed ma-5" :size="70" :width="7"
                            indeterminate></v-progress-circular>
                    </v-flex>
                    <v-flex xs12 class="d-flex justify-center ma-4">
                        <h3>Aanpassen commissie</h3>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog> -->
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            headers: [
                {
                    text: "Nummer",
                    align: "start",
                    sortable: false,
                    value: "index",
                },
                { text: "Naam", sortable: false, value: "name" },
                { text: "Voorzitter", sortable: false, value: "preces" },
                { text: "Leden", sortable: false, value: "members" },
                // { text: "Aanpassen", sortable: false, value: "actions" },
            ],
            newEntryDialog: false,
            newItem: {},
            newEntryStepper: 1,
            feedback: null,
            buzzy: false,
            editItem: {},
            editItemDialog: false,
            editItemStepper: 1,
            newCommission: {
                name: '',
                index: '',
                description: '',
                preces: null,
                members: [],
                oldCommissions: []
            },
            convertedCommissions: [],
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        },
        oldWorkgroups() {
            return this.$store.state.workgroups;
        },
        users() {

            return this.$store.state.users;
        },
        commissions() {
            return this.$store.state.commissionStore.commissions;
        },
    },
    watch: {
        commissions() {
            this.convertCommissions();
        },
        users() {
            this.convertCommissions();
        }
    },
    methods: {
        convertCommissions(){
            let coms = []
            this.commissions.forEach(commission => {
                let com = {
                    id: commission.id,
                    name: commission.name,
                    index: commission.index,
                    preces: [],
                    members: [],
                }
                this.users.forEach(user => {
                    if(user.permissions?.commissions?.includes(`${com.id}-vz`) ){
                        com.preces.push(user.name);
                    } else if(user.permissions?.commissions?.includes(`${com.id}`)){
                        com.members.push(user.name);
                    }
                });
                coms.push(com);
            });
            coms.sort((a, b) => a.index - b.index);
            this.convertedCommissions = coms;
        },


        resetForm() {
            this.newCommission = {
                name: '',
                index: '',
                description: '',
                preces: null,
                members: [],
                oldCommissions: []
            };
            this.newEntryDialog = false;
            this.feedback = '';
        },
        validateAndSave() {
            if (this.$refs.addComForm.validate()) {
                this.saveNewCommission();
            } else {
                this.feedback = 'Please fill in all required fields.';
            }
        },
        saveNewCommission() {
            this.buzzy = true;
            this.$store.dispatch("commissionStore/addCommission", { commission: this.newCommission })
                .then((result) => {
                    if (result.success) {
                        this.buzzy = false;
                        this.newEntryDialog = false;
                        this.resetForm();
                    } else {
                        this.feedback = result.message;
                        this.buzzy = false;
                    }
                })
                .catch((error) => {
                    console.error(error); // Log any errors
                    this.feedback = 'An error occurred while saving the commission';
                    this.buzzy = false;
                });
        },
    },

    created() {
        this.$store.dispatch("loadUsers");
    },
    mounted(){
        this.convertCommissions();
    }
};
</script>

<style></style>