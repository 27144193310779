<template>
    <div>
        <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
                <span class="ahcRed subheading">
                    <b>Interactieve draaiboeken</b>
                </span>
                <v-spacer></v-spacer>
                <div class="d-flex" v-if=" !addDraaiboek">
                    <v-icon color="#cc212f" @click="addDraaiboek = true">mdi-file-plus-outline</v-icon>
                </div>
            </v-system-bar>
            <v-card-text v-if="
                (filteredDraaiboeken == null || filteredDraaiboeken.length == 0)
            ">
                <span>
                    Er is nog geen interactief draaiboek gemaakt. Het is de taak van de
                    voorzitter om dit te verzorgen.
                </span>
            </v-card-text>
            <v-expansion-panels v-else>
                <v-expansion-panel class="mb-2" v-for="category in filteredDraaiboeken" :key="category.id">
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col cols="10">
                                <v-chip :color="category.color" text-color="white">
                                    {{ category.name }}
                                </v-chip>
                            </v-col>
                            <v-col cols="1">
                                <v-icon color="#cc212f"
                                    @click="editDraaiboekDialog = true, editDraaiboek = category">mdi-pencil</v-icon>
                            </v-col>
                            <v-col cols="1" v-if="isPreces">
                                <v-icon color="#cc212f"
                                    @click="deleteDraaiboekDialog = true, deleteDraaiboek = category">mdi-delete</v-icon>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row  class="my-1">
                            <v-col cols="3">
                                <v-btn @click="addSubCatDialog = true, newSubCat.parent = category.id">subcategorie
                                    toevoegen</v-btn>
                            </v-col>
                        </v-row>
                        <v-expansion-panels v-if="category.subCategories && category.subCategories.length > 0">
                            <v-expansion-panel v-for="subCat in category.subCategories" :key="subCat.id">
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col cols="10">
                                            <span>
                                                {{ subCat.name }}
                                            </span>
                                        </v-col>
                                        <v-col cols="1">
                                            <v-icon color="#cc212f"
                                                @click="addSubCatDialog = true, newSubCat = subCat">mdi-pencil</v-icon>
                                        </v-col>
                                        <v-col cols="1" v-if="isPreces">
                                            <v-icon color="#cc212f"
                                                @click="deleteSubCatDialog = true, deleteSubCatData = { id: subCat.id, parent: subCat.parent }">mdi-delete</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row >
                                        <v-col cols="12">
                                            <v-btn
                                                @click="addItemDialog = true, newItem.parent = subCat.id, newItem.grandParent = subCat.parent">draaiboek
                                                item toevoegen</v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-data-table :headers="[
                                        { text: 'Wat', value: 'name' },
                                        { text: '(Hoofd)verantwoordelijke', value: 'responsable' },
                                        { text: 'Wanneer', value: 'when' },
                                    ]" :items="getSortedItems(subCat.items)"  :expanded.sync="expanded[category.id]"
                                        item-key="id" show-expand hide-default-footer disable-pagination>
                                        <template v-slot:expanded-item="{ headers, item }">
                                            <td :colspan="headers.length">
                                                <v-row class="ma-2">
                                                    <v-col cols="12">
                                                    <v-icon color="#cc212f"
                                                    @click="addItemDialog = true, newItem = item">mdi-pencil</v-icon>
                                                    <v-icon v-if="isPreces" color="#cc212f"
                                                @click="deleteItem(item)">mdi-delete</v-icon>
                                            </v-col>
                                                </v-row>
                                                <v-row class="ma-2">
                                                    <v-col cols="12">
                                                        <span
                                                            style="word-break: break-word; overflow-wrap: break-word; white-space: pre-wrap"><b>Omschrijving:</b><br />
                                                            {{ item.description }}</span>
                                                    </v-col>
                                                </v-row>
                                            </td>
                                        </template>
                                        <template v-slot:[`item.when`]="{ item }">
                                            <span>
                                                {{ getWhen(item) }}
                                            </span>
                                        </template>
                                    </v-data-table>

                                </v-expansion-panel-content>
                            </v-expansion-panel>

                        </v-expansion-panels>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
        <v-dialog transition="dialog-top-transition" max-width="800" v-model="addDraaiboek">
            <v-card v-if="!buzzy">
                <v-system-bar class="pa-4" color="#ebb63b">
                    <span class="ahcRed subheading">
                        <b>Nieuw draaiboek aanmaken</b>
                    </span>
                </v-system-bar>
                <v-card-text>
                    <v-text-field v-model="newDraaiboekCategory.name" label="Naam" required />
                    <span>Tag kleur:</span>
                    <v-color-picker v-model="newDraaiboekCategory.color" label="Tag kleur" dot-size="25"
                        swatches-max-height="200"></v-color-picker>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="green darken-1" text
                        @click="(addDraaiboek = false), (newDraaiboekCategory = {})">Annuleren</v-btn>

                    <v-btn color="green darken-1" text v-if="newDraaiboekCategory.name"
                        @click="saveNewCategory">Opslaan</v-btn>
                </v-card-actions>
            </v-card>
            <v-card class="ma-5" elevation="0" v-if="buzzy">
                <v-card-text>
                    <v-row>
                        <v-progress-circular class="ma-5 ahcRed" :size="70" :width="7"
                            indeterminate></v-progress-circular>
                    </v-row>
                    <v-row>
                        <h3>Opslaan draaiboek</h3>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog transition="dialog-top-transition" max-width="800" v-model="editDraaiboekDialog">
            <v-card v-if="!buzzy">
                <v-system-bar class="pa-4" color="#ebb63b">
                    <span class="ahcRed subheading">
                        <b>Draaiboek aanpassen</b>
                    </span>
                </v-system-bar>
                <v-card-text>
                    <v-text-field v-model="editDraaiboek.name" label="Naam" required />
                    <span>Tag kleur:</span>
                    <v-color-picker v-model="editDraaiboek.color" label="Tag kleur" dot-size="25"
                        swatches-max-height="200"></v-color-picker>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="green darken-1" text
                        @click="(editDraaiboekDialog = false), (editDraaiboek = {})">Annuleren</v-btn>

                    <v-btn color="green darken-1" text v-if="editDraaiboek.name"
                        @click="saveNewCategoryMeta()">Opslaan</v-btn>
                </v-card-actions>
            </v-card>
            <v-card class="ma-5" elevation="0" v-if="buzzy">
                <v-card-text>
                    <v-row>
                        <v-progress-circular class="ma-5 ahcRed" :size="70" :width="7"
                            indeterminate></v-progress-circular>
                    </v-row>
                    <v-row>
                        <h3>Opslaan draaiboek</h3>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog transition="dialog-top-transition" max-width="800" v-model="addSubCatDialog">
            <v-card v-if="!buzzy">
                <v-system-bar class="pa-4" color="#ebb63b">
                    <span class="ahcRed subheading">
                        <b>Subcategorie toevoegen / aanpassen</b>
                    </span>
                </v-system-bar>
                <v-card-text>
                    <v-text-field v-model="newSubCat.name" label="Naam" placeholder="Algemeen" required />
                </v-card-text>
                <v-card-actions>
                    <v-btn color="green darken-1" text
                        @click="(addSubCatDialog = false), (newSubCat = {})">Annuleren</v-btn>

                    <v-btn color="green darken-1" text v-if="newSubCat" @click="saveSubCategory">Opslaan</v-btn>
                </v-card-actions>
            </v-card>
            <v-card class="ma-5" elevation="0" v-if="buzzy">
                <v-card-text>
                    <v-row>
                        <v-progress-circular class="ma-5 ahcRed" :size="70" :width="7"
                            indeterminate></v-progress-circular>
                    </v-row>
                    <v-row>
                        <h3>Opslaan draaiboek</h3>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog transition="dialog-top-transition" max-width="800" v-model="deleteDraaiboekDialog">
            <v-card v-if="!buzzy">
                <v-system-bar class="pa-4" color="#ebb63b">
                    <span class="ahcRed subheading">
                        <b>Draaiboek verwijderen</b>
                    </span>
                </v-system-bar>
                <v-card-text>
                    <span>Weet je zeker dat je het draaiboek "{{ deleteDraaiboek.name }}" wilt verwijderen?</span>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="green darken-1" text
                        @click="(deleteDraaiboekDialog = false), (deleteDraaiboek = {})">Annuleren</v-btn>

                    <v-btn color="green darken-1" text @click="archiveDraaiboek">Verwijderen</v-btn>
                </v-card-actions>
            </v-card>
            <v-card class="ma-5" elevation="0" v-if="buzzy">
                <v-card-text>
                    <v-row>
                        <v-progress-circular class="ma-5 ahcRed" :size="70" :width="7"
                            indeterminate></v-progress-circular>
                    </v-row>
                    <v-row>
                        <h3>Opslaan draaiboek</h3>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog transition="dialog-top-transition" max-width="800" v-model="deleteSubCatDialog">
            <v-card v-if="!buzzy">
                <v-system-bar class="pa-4" color="#ebb63b">
                    <span class="ahcRed subheading">
                        <b>Subcategorie verwijderen</b>
                    </span>
                </v-system-bar>
                <v-card-text>
                    <span>Weet je zeker dat je de subcategorie wilt verwijderen?</span>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="green darken-1" text
                        @click="(deleteSubCatDialog = false), (deleteSubCatData = {})">Annuleren</v-btn>

                    <v-btn color="green darken-1" text @click="deleteSubCat">Verwijderen</v-btn>
                </v-card-actions>
            </v-card>
            <v-card class="ma-5" elevation="0" v-if="buzzy">
                <v-card-text>
                    <v-row>
                        <v-progress-circular class="ma-5 ahcRed" :size="70" :width="7"
                            indeterminate></v-progress-circular>
                    </v-row>
                    <v-row>
                        <h3>Opslaan draaiboek</h3>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog transition="dialog-top-transition" max-width="800" v-model="addItemDialog">
            <v-card v-if="!buzzy">
                <v-system-bar class="pa-4" color="#ebb63b">
                    <span class="ahcRed subheading">
                        <b>Item toevoegen</b>
                    </span>
                </v-system-bar>
                <v-card-text>
                    <v-text-field v-model="newItem.name" label="Naam" placeholder="Bakker bellen" required />
                    <v-textarea v-model="newItem.description" label="Omschrijving / notitie"
                        placeholder="tel: 06-11223344" />
                    <v-select v-model="newItem.responsable" :items="commissionMembers"
                        label="(Hoofd)verantwoordelijke" />
                    <v-radio-group label="Type" v-model="newItem.timeType">
                        <v-radio label="Vooraf" value="upFront"></v-radio>
                        <v-radio label="Tijdens het evenement" value="duringEvent"></v-radio>
                        <v-radio label="Na het evenement" value="afterEvent"></v-radio>
                    </v-radio-group>
                    <v-select v-if="newItem.timeType && (newItem.timeType == 'upFront' || newItem.timeType == 'afterEvent')" v-model="newItem.period"
                        :items="months" item-text="label" item-value="label" label="Wanneer moet het gebeuren" 
                        ></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="green darken-1" text
                        @click="(addItemDialog = false), (newItem = {})">Annuleren</v-btn>

                    <v-btn color="green darken-1" text v-if="newItem.name" @click="saveItem">Opslaan</v-btn>
                </v-card-actions>
            </v-card>
            <v-card class="ma-5" elevation="0" v-if="buzzy">
                <v-card-text>
                    <v-row>
                        <v-progress-circular class="ma-5 ahcRed" :size="70" :width="7"
                            indeterminate></v-progress-circular>
                    </v-row>
                    <v-row>
                        <h3>Opslaan draaiboek</h3>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>
<script>
export default {
    name: "interactive-draaiboek",
    props: {
        isPreces: {
            type: Boolean,
            default: false,
        },
        draaiboekCategories: {
            type: Array,
            default: null,
        },
        commissionId: {
            type: String,
            required: true,
        },


    },
    data() {
        return {
            addDraaiboek: false,
            buzzy: false,
            newDraaiboekCategory: {},
            editDraaiboekDialog: false,
            editDraaiboek: {},
            newSubCat: {},
            addSubCatDialog: false,
            deleteDraaiboekDialog: false,
            deleteDraaiboek: {},
            deleteSubCatDialog: false,
            deleteSubCatData: {},
            addItemDialog: false,
            newItem: {},
            months: [
                { label: "Januari", value: 8 },
                { label: "Februari", value: 9 },
                { label: "Maart", value: 10 },
                { label: "April", value: 11 },
                { label: "Mei", value: 12 },
                { label: "Juni", value: 1 },
                { label: "Juli", value: 2 },
                { label: "Augustus", value: 3 },
                { label: "September", value: 4 },
                { label: "Oktober", value: 5 },
                { label: "November", value: 6 },
                { label: "December", value: 7 }
            ],
          
            expanded: [],

        };
    },
    onMounted() {
        this.initializeExpanded();
    },  
    computed: {

        filteredDraaiboeken() {
            return this.draaiboekCategories.filter((category) => {
                return !category.archived;
            });
        },
        commissionMembers() {
            let members = [];
            let comMembers = this.$store.getters["commissionStore/getCommissionUsers"](this.commissionId);
            let preces = this.$store.getters["commissionStore/getCommissionPreces"](this.commissionId);
            comMembers.forEach((member) => {
                members.push(member.name);

            });
            preces.forEach((preces) => {
                members.push(preces.name);
            });
            return members;
        }

    },
    methods: {

        initializeExpanded() {
      this.expanded = {};
      this.draaiboekCategories.forEach(category => {
        category.subCategories.forEach(subCategory => {
          this.expanded[subCategory.id] = [];
        });
      });
      
    },
        getWhen(item) {
            if (item.timeType == "upFront" || item.timeType == "afterEvent") {
                return `${item.period}`;
            } else {
                return "Tijdens het evenement";
            }
        },
        getSortedItems(items) {
            if(!items) return [];
      // Separate items with timeType "upFront" and others
      
      const upFrontItems = items?.filter(item => item.timeType === "upFront");
      const otherItems = items?.filter(item => item.timeType !== "upFront" && item.timeType !== "afterEvent");
      const afterEventItems = items?.filter(item => item.timeType === "afterEvent");

         // Sort upFrontItems by period using the months array
         upFrontItems.sort((a, b) => {
        const periodA = this.months.find(month => month.label === a.period);
        const periodB = this.months.find(month => month.label === b.period);
        if (periodA && periodB) {
          return periodA.value - periodB.value;
        }
        return 0;
      });
      afterEventItems.sort((a, b) => {
        const periodA = this.months.find(month => month.label === a.period);
        const periodB = this.months.find(month => month.label === b.period);
        if (periodA && periodB) {
          return periodA.value - periodB.value;
        }
        return 0;
      });

      // Combine sorted upFrontItems with otherItems
      return [...upFrontItems, ...otherItems, ...afterEventItems];
    },
        saveNewCategory() {
            this.buzzy = true;
            this.newDraaiboekCategory.commissionId = this.commissionId;
            this.newDraaiboekCategory.id = Date.now();
            this.newDraaiboekCategory.color = this.newDraaiboekCategory.color.hex;
            this.$store.dispatch("commissionStore/saveDraaiboekCategory", {
                commissionId: this.commissionId,
                category: this.newDraaiboekCategory,
            }).then((result) => {
                if (result.success) {
                    this.addDraaiboek = false;
                    this.newDraaiboekCategory = {};
                    this.buzzy = false;
                } else {
                    alert("Er is iets fout gegaan bij het opslaan van het draaiboek");
                    this.buzzy = false;
                }
            });
        },
        saveNewCategoryMeta() {
            this.buzzy = true;
            this.editDraaiboek.color = this.editDraaiboek.color.hex;
            this.$store.dispatch("commissionStore/saveDraaiboekCategory", {
                commissionId: this.commissionId,
                category: this.editDraaiboek,
            }).then((result) => {
                if (result.success) {
                    this.editDraaiboekDialog = false;
                    this.editDraaiboek = {};
                    this.buzzy = false;
                } else {
                    alert("Er is iets fout gegaan bij het opslaan van het draaiboek");
                    this.buzzy = false;
                }
            });
        },
        saveSubCategory() {
            this.buzzy = true;
            if (this.newSubCat.id == null) {
                this.newSubCat.id = Date.now();
            }

            let parentCat = null;
            this.draaiboekCategories.forEach((category) => {
                if (category.id == this.newSubCat.parent) {
                    parentCat = category;
                    if (parentCat.subCategories == null) {
                        parentCat.subCategories = [];
                    }
                    parentCat.subCategories.forEach((subCat, index) => {
                        if (subCat.id == this.newSubCat.id) {
                            parentCat.subCategories.splice(index, 1);
                        }
                    });
                    parentCat.subCategories.push(this.newSubCat);
                }
            });
            this.$store.dispatch("commissionStore/saveDraaiboekCategory", {
                commissionId: this.commissionId,
                category: parentCat,
            }).then((result) => {
                if (result.success) {
                    this.addSubCatDialog = false;
                    this.newSubCat = {};
                    this.buzzy = false;
                    this.initializeExpanded();
                } else {
                    alert("Er is iets fout gegaan bij het opslaan van het draaiboek");
                    this.buzzy = false;
                }
            });
        },
        archiveDraaiboek() {
            this.buzzy = true;
            this.deleteDraaiboek.archived = true;
            this.$store.dispatch("commissionStore/saveDraaiboekCategory", {
                commissionId: this.commissionId,
                category: this.deleteDraaiboek,
            }).then((result) => {
                if (result.success) {
                    this.deleteDraaiboekDialog = false;
                    this.deleteDraaiboek = {};
                    this.buzzy = false;
                } else {
                    alert("Er is iets fout gegaan bij het opslaan van het draaiboek");
                    this.buzzy = false;
                }
            });
        },
        deleteSubCat() {
            this.buzzy = true;
            let parentCat = null;
            this.draaiboekCategories.forEach((category) => {
                if (category.id == this.deleteSubCatData.parent) {
                    parentCat = category;
                    if (parentCat.subCategories == null) {
                        parentCat.subCategories = [];
                    }
                    parentCat.subCategories.forEach((subCat, index) => {
                        if (subCat.id == this.deleteSubCatData.id) {
                            parentCat.subCategories.splice(index, 1);
                        }
                    });
                }
            });
            this.$store.dispatch("commissionStore/saveDraaiboekCategory", {
                commissionId: this.commissionId,
                category: parentCat,
            }).then((result) => {
                if (result.success) {
                    this.deleteSubCatDialog = false;
                    this.deleteSubCatData = {};
                    this.buzzy = false;
                } else {
                    alert("Er is iets fout gegaan bij het opslaan van het draaiboek");
                    this.buzzy = false;
                }
            });
        },
        saveItem() {
            this.buzzy = true;
            if(this.newItem.id == null){
                this.newItem.id = Date.now();
            }
            let parentCat = null;
            this.newItem.commissionId = this.commissionId;
            this.draaiboekCategories.forEach((category) => {
                if (category.id == this.newItem.grandParent) {
                    category.subCategories.forEach((subCat) => {
                        if (subCat.id == this.newItem.parent) {
                            if (subCat.items == null) {
                                subCat.items = [];
                            }
                            subCat.items.forEach((item, index) => {
                                if (item.id == this.newItem.id) {
                                    subCat.items.splice(index, 1);
                                }
                            });
                            subCat.items.push(this.newItem);
                        }
                    });
                    parentCat = category;
                }
            });
            this.$store.dispatch("commissionStore/saveDraaiboekCategory", {
                commissionId: this.commissionId,
                category: parentCat,
            }).then((result) => {
                if (result.success) {
                    this.addItemDialog = false;
                    this.newItem = {};
                    this.buzzy = false;
                } else {
                    alert("Er is iets fout gegaan bij het opslaan van het draaiboek");
                    this.buzzy = false;
                }
            });
        },
        
        deleteItem(item) {
            this.buzzy = true;
            let parentCat = null;
            this.draaiboekCategories.forEach((category) => {
                if (category.id == item.grandParent) {
                    category.subCategories.forEach((subCat) => {
                        if (subCat.id == item.parent) {
                            subCat.items.forEach((subItem, index) => {
                                if (subItem.id == item.id) {
                                    subCat.items.splice(index, 1);
                                }
                            });
                        }
                    });
                    parentCat = category;
                }
            });
            this.$store.dispatch("commissionStore/saveDraaiboekCategory", {
                commissionId: this.commissionId,
                category: parentCat,
            }).then((result) => {
                if (result.success) {
                    this.buzzy = false;
                } else {
                    alert("Er is iets fout gegaan bij het opslaan van het draaiboek");
                    this.buzzy = false;
                }
            });
        },
    },
}

</script>